@import '/src/styles/variables';

.teaser__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.teaser__subtitle {
  color: $green;
  font-size: 14px;

  @media (max-width: $lg) {
    margin-top: 15px;
  }
}

.teaser__title {
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}

.teaser__content {
  max-width: 540px;
  margin: 0 20px;
}

.teaser__image {
  width: 470px;

  img {
    width: 100%;
    height: 570px;
    object-fit: cover;
    border-radius: 16px;
    border: 10px solid white;
    box-shadow: 0 0 40px 0 rgba($gray, 0.15);
  }
}

@media (max-width: $lg) {
  .teaser__wrapper {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .teaser__content {
    margin-top: 30px;
    max-width: 400px;
  }

  .teaser--right {
    .teaser__image {
      order: 1;
    }

    .teaser__content {
      order: 2;
    }
  }
}
