@import 'styles/variables';

h6.sponsor-title {
  color: $light-gray;
}

.sponsor-grayscale {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */

  &:hover {
    filter: none; /* IE6-9 */
    -webkit-filter: grayscale(0); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(0); /* Microsoft Edge and Firefox 35+ */
  }
}

.sponsor-1 {
  height: 10vh;
}

.sponsor-2 {
  height: 9vh;
}

.sponsor-3 {
  height: 8vh;
}
