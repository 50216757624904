// heights are temporary just to see the sections
.gradient {
  height: auto;
  background: linear-gradient(to bottom, #c9eef9 0%, #fdedee 100%);
}

.bg-blue {
  height: auto;
  background-color: $light-blue;
}

.bg-pink {
  height: auto;
  background-color: $light-pink;
}

.zig-zag {
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    top: -20px;
    left: 0;
    background: linear-gradient(-45deg, #ffffff 16px, #ffffff 16px, #ffffff 16px, transparent 0),
      linear-gradient(45deg, #ffffff 16px, transparent 0);
    background-position: left top;
    background-repeat: repeat-x;
    background-size: 22px 32px;
    content: ' ';
    display: block;
    height: 20px;
    width: 100%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -16px;
    left: 0px;
    background: linear-gradient(-45deg, transparent 16px, #ffffff 0),
      linear-gradient(45deg, transparent 16px, #ffffff 0);
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 22px 32px;
    width: 100%;
    height: 16px;
  }
}
