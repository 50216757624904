@import 'styles/variables';

.person-text {
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;

  &:hover {
    color: lightgrey
  }
}
