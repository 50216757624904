@import 'styles/variables';

.card-item {
  margin: 30px;
}

.custom-dots {
  position: inherit;
  margin-top: 10px;

  button {
    border-color: $color-primary;
  }

  .react-multi-carousel-dot--active {
    button {
      background-color: $color-primary;
    }
  }
}

.custom-arrow {
  color: #000958;
  background-color: white;
  position: absolute;
  height: 60px;
  width: 60px;
  padding: inherit;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media (max-width: $lg) {
    display: none;
  }

  .blue-arrow {
    width: 24px !important;

    path {
      stroke: $color-primary;
    }
  }

  &.custom-arrow-left {
    left: 0px;
    border-radius: 0px 10px 10px 0px;

    .blue-arrow {
      transform: rotate(180deg);
    }
  }

  &.custom-arrow-right {
    right: 0px;
    border-radius: 10px 0px 0px 10px;
  }
}

.react-multi-carousel-list {
  position: initial !important;
  padding: 0;
}
