/* colors */
$brand-color: #000958; // dark blue
$white: #fff;
$blue: #20bce9;
$light-blue: #c9eef9;
$green: #01c2a9;
$gray: #131a27;
$light-gray: #dedede;
$pink: #ffb8b8;
$light-pink: #fdedee;
$red: #ff124b;

$color-primary: $brand-color;
$color-secondary: $green;

/* text colors */
$text-color: $gray;

/* font family */
$font-family-primary: 'Roboto Mono', sans-serif;

/* breakpoints */
$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 480px;
$xs: 0;
