@import 'styles/variables';

.person-card {
  display: flex;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $light-gray;
  position: relative;
  margin: 10px;

  flex-direction: column;
  height: 406px;
  max-width: 270px;

  @media (max-width: $lg) {
    flex-direction: row;
    max-width: unset;
    height: 200px;
  }
}

.person-card__img {
  display: flex;

  img {
    margin: 30px auto;
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 100%;

    @media (max-width: $lg) {
      margin: 30px;
      width: 70px;
      height: 70px;
    }
  }
}

.person-card__body {
  display: flex;
  flex-direction: column;
  margin: 0 30px;
  height: 100%;

  @media (max-width: $lg) {
    margin: 30px 7px 30px 0;
    width: 100%;
  }

  h5 {
    font-size: 18px;
  }
}

.person-card__name {
  margin-top: 0 !important;
  font-size: 0.8rem;
}

.person-card__job {
  font-family: Roboto Mono;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  // @media (max-width: $md) {
  //   display: none
  // }
}

.person-card__company {
  font-family: Roboto Mono;
  font-size: 0.8rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  text-decoration: underline;

  //  @media (max-width: $md) {
  //   text-decoration: none;
  // }
}

.person-card__responsibilities {
  font-family: Roboto Mono;
  font-size: 0.8rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0em;
  // text-decoration: underline;

  //  @media (max-width: $md) {
  //   text-decoration: none;
  // }
}

.person-card__location {
  font-family: Roboto Mono;
  font-size: 0.8rem;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0em;

  //  @media (max-width: $md) {
  //   text-decoration: none;
  // }
}

.person-card__icons {
  margin-top: auto;
  margin-bottom: 30px;

  @media (max-width: $lg) {
    margin-bottom: 60px;
  }

  .social-link-svg svg {
    fill: $color-primary;
    height: 30px;

    @media (max-width: $lg) {
      height: 15px;
    }
  }

  // @media (max-width: $md) {
  //   display:none
  // }
}

