@import 'styles/variables';

.links {
  &--dark {
    .social-link-svg svg path {
      fill: $color-primary;
    }
  }

  &--light {
    .social-link-svg svg path {
      fill: $white;
    }
  }
}
