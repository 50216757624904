@import 'styles/variables';

.social-bar {
  background-color: $brand-color;
  padding-top: 10px;
  height: 50px;
  height: 36px;
  padding-top: 4px;

  @media (max-width: $lg) {
    padding-top: 10px;
    height: 50px;
  }

  .links {
    margin: auto;
    text-align: center;
    float: right;

    @media (max-width: $lg) {
      float: unset;

      a {
        margin: 0 1rem;
      }
    }
  }
}
