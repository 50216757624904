@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap');
@import './variables';
@import './backgrounds';
@import './buttons';

//
// Customizar Bootsrap nesta secção (INÍCIO)
//

// Customizar cores default
$success: $green;
$danger: $red;

// Customizar toast
$toast-max-width: 1170px;
$toast-header-background-color: $white;
$toast-header-border-color: transparent;
$toast-background-color: $white;
$toast-border-radius: 8px;
$toast-header-color: $color-secondary;

// Customizar grid-breakpoints
$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl
);

// Customizar navbar
$nav-link-color: $color-primary;
$nav-link-hover-color: $color-secondary;
$navbar-light-color: $color-primary;
$navbar-light-active-color: $color-secondary;
$navbar-light-hover-color: $color-primary;

// Customizar dropdown
$dropdown-link-active-bg: $white;
$dropdown-link-active-color: $color-secondary;
$dropdown-link-color: $color-primary;
$dropdown-link-hover-color: $color-primary;
$dropdown-link-hover-bg: $white;

@import '~bootstrap/scss/bootstrap';

//
// Customizar Bootsrap nesta secção (FIM)
//

body {
  margin: 0;
  padding: 0 !important;
  font-family: $font-family-primary;
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-primary;
}

h6 {
  color: $color-secondary;
}

p {
  color: $text-color;
  font-size: 14px;
  line-height: 28px;
}

a {
  text-decoration: none;
  color: $color-secondary;
}

hr {
  background-color: $light-gray;
  opacity: 1;
}

// utilities

.padding-top-bottom-medium {
  padding-top: 76px;
  padding-bottom: 76px;
}

.padding-top-medium {
  padding-top: 76px;
}
.padding-bottom-medium {
  padding-bottom: 76px;
}

.padding-top-bottom-large {
  padding-top: 120px;
  padding-bottom: 120px;
}

.box-radius {
  border-radius: 16px;
}

.box-border {
  border-color: $white;
  background-color: $white;
  padding: 6px;
  object-fit: contain;
}

.margin-right-15 {
  margin-right: 15px;
}

.fs-x5 {
  font-size: $font-size-base * 5 !important;
  line-height: $font-size-base * 5 !important;
}
.text-secondary-color {
  color: $color-secondary;
}

.fs-xxl {
  font-size: 3.5rem !important;
  line-height: 3.5rem !important;
}

.text-700-weight {
  font-weight: 700;
}

.navigation-position {
  margin: 0 auto;
  position: absolute;
  width: 100%;
  color: $color-primary;
}

.padding-top-first-section {
  &:first-child {
    padding-top: 3rem !important;
  }
}

.breadcrumb-item.active {
  color: $color-secondary;
  font-weight: 700;
}

.breadcrumb > li + li:before {
  font-weight: 700 !important;
  color: $color-primary !important;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E") !important;
}

li.special-quote-list {
  list-style: disc !important;
  margin-left: 10% !important;
}

.arrow-size-default {
  width: 14px;
}

#img-missao {
  max-width: unset;
}

.label-option {
  color: #767f85;
}

.asterisk-required {
  font-weight: bolder;
  margin-left: 10px;
  color: #000958;
}

.display-flex-webkit {
  display: flex;
}
