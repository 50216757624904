@import 'styles/variables';

$assetPath: '../../';

#footer {
  //   background-image: url( '../../assets/ardc-logo--footer-2.svg' );
  //  background-repeat: no-repeat;
  //  background-size: 100%;
}

hr {
  border: 1px solid #dedede;
}

ul {
  text-decoration: none;
  padding-left: 0;
}

li {
  list-style: none;
}

a {
  color: $color-primary;
  text-decoration: underline;
}

.card {
  text-align: left;
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $light-gray;
  max-width: 570px;
  height: 100%;
  position: relative;

  @media (max-width: $lg) {
    margin-bottom: 30px;
  }
}

.card-img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 100%;
}

.card-body {
  padding: 23px 30px;
  line-height: 28px;
  height: 100%;

  span {
    display: block;
    font-size: 12px;
    color: $color-secondary;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 18px;
  }
}

hr {
  border: 1px solid #dedede;
}

.card-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card-author {
  bottom: 40px;
  align-items: baseline;
  margin-top: 15px;
  position: absolute;
}

.card-text {
  min-height: 25vh;
}

.testimonials {
  margin-top: 100px;
}

.social-link-svg {
  svg {
    @media (max-width: $lg) {
      height: 30px;
      width: auto;
    }
  }
}

.legal-copy {
  p {
    color: $color-primary;
    font-size: 12px;
  }
}

.centering-col {
  display: flex;
  @media (max-width: $lg) {
    margin: 0 auto;
    width: fit-content;
  }
}

.centering-text-mobile {
  @media (max-width: $md) {
    text-align: center;
  }
}
