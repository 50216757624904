@import '../../styles/variables';

.organization-feature__image {
  width: 80px;
}

.organization-feature__wrapper {
  text-align: center;
  float: center;
  padding: 10px;
}
